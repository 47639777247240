import { Box, Button, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

function ProfilePage({ setIsSignedIn }) {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      console.log('token', token)
      const decoded = jwtDecode(token);
      const { email } = decoded;
      setEmail(email);
    }
  });

  const logout = () => {
    // Remove the JWT from localStorage
    localStorage.removeItem('token');
  
    // Update state to reflect that user is no longer authenticated
    setEmail('');
    setIsSignedIn(false);
    navigate('/');
  };

  return (
    <Box textAlign='center' mt={10} maxW='container.md' mx='auto' px={5}>
      <Stack direction='column' gap={3} align='center'>
        <Text>You are signed in as <Text as='span' color='blue.500' fontWeight='bold'>{email}</Text>.</Text>
        <Button colorScheme='blue' onClick={logout} maxW='min-content'>Sign Out</Button>
      </Stack>
    </Box>
  );
}

export default ProfilePage;
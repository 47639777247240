import React from 'react';
import { Box, Heading, Stack, Text, Link as ChakraLink } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <Box textAlign='center' mt={10} maxW='container.md' mx='auto'>
      <Heading as='h1' size='2xl' mb={4}>
        404 - Page Not Found
      </Heading>
      <Text fontSize='xl'>
        Oops! The page you are looking for does not exist.
      </Text>
      <Stack direction='row' justify='center' align='center'>
        <Text fontSize='xl'>Is this a bug? </Text>
        <ChakraLink as={RouterLink} to='/contact-us' _hover={{ textDecoration: 'none' }}>
          <Text color='blue.500' fontSize='xl'>Report it here.</Text>
        </ChakraLink>
      </Stack>
    </Box>
  );
};

export default NotFoundPage;
const filterOptions = {
  'tags': {
    "Atomic Structure": [
      "Orbital Properties",
      "Quantum Numbers",
      "Quantum Mechanics",
      "Wavefunction Plots",
      "History of Atomic Physics",
      "Atomic Models",
      "Nuclear Structure",
      "Nuclear Decay",
      "Isotopes",
      "Electron Configuration",
      "Atomic Emission Spectroscopy",
      "Photoelectron Spectroscopy",
      "Magnetism",
      "Zeff and Shielding",
      "Rydberg Equation",
      "Spectral Series",
      "Photoelectric Effect"
    ],
    "Periodicity": [
      "Atomic Radii",
      "Ionization Energy",
      "Electron Affinity",
      "Electronegativity",
      "Oxidation States",
      "Inert Pair",
      "Anomalies",
      "Melting Point",
      "Boiling Point",
      "Isoelectronic Species",
      "D-block contraction",
      "F-block contraction",
      "Second Period Anomaly",
      "Relativistic Effects"
    ],
    "Molecular Structure and Bonding": [
      "Isoelectronic Species",
      "Molecular Geometry",
      "Bond Angle",
      "Bond Length",
      "Bond Strength",
      "Isomerism",
      "Count Isomers",
      "Structural Isomerism",
      "Geometric Isomerism",
      "Optical Isomerism",
      "Stereoisomerism",
      "Complex Isomers",
      "Complex Structures",
      "Conformations",
      "Resonance",
      "Lewis Structures",
      "Formal Charge",
      "Determine Polarity from Structure",
      "VSEPR",
      "Crystal Field Theory",
      "Ligand Field Theory",
      "Valence Bond Theory",
      "Molecular Orbital Theory",
      "Orbital Hybridization Theory",
      "Intermolecular Forces",
      "Determine Bond Type",
      "Determine Bond Order"
    ],
    "Stoichiometry": [
      "Chemical Formula Determination",
      "Quantity Conversion",
      "Limiting Reactants",
      "Combustion Analysis",
      "Percent Composition of Mixtures",
      "Percent Composition of Pure Compounds",
      "Product Yield",
      "Identify Unknown Compound",
      "Balancing Chemical Reactions"
    ],
    "States of Matter and Solutions": [
      "Van't Hoff Factor",
      "Calculate Molarity / Molality",
      "Concentration after Dilution",
      "Colligative Properties",
      "Boiling Point Elevation",
      "Freezing Point Depression",
      "Osmotic Pressure",
      "Precipitation Reactions",
      "Electrolyte Strength",
      "Beer's Law",
      "Raoult's Law",
      "Henry's Law",
      "Diffusion / Effusion",
      "Ideal Gas Properties",
      "Ideal Gas Calculations",
      "Non-Ideal Gas Properties",
      "Kinetic Molecular Theory",
      "Vapor Pressure of Solutions",
      "Clausius-Clapeyron Equation",
      "Liquid-Liquid Mixtures",
      "Azeotropic Mixtures",
      "Phase Diagrams",
      "Phase Equilibria",
      "Gibbs Phase Rule",
      "Intermolecular Forces",
      "Unit Cells",
      "Bragg's Law",
      "Types of Solids",
      "Properties of Ionic Solids",
      "Properties of Molecular Solids",
      "Properties of Network Covalent Solids",
      "Properties of Metallic Solids",
      "Properties of Amorphous Solids",
      "Electrical Conductivity of Solutions",
      "Properties of Solids, Liquids, Gases",
      "Colloidal Mixtures",
      "Supercritical Fluids",
      "Like Dissolves Like Solubility Rule"
    ],
    "Descriptive": [
      "Chemistry Trivia",
      "Color of Precipitates",
      "Aqueous Metal Ion Colors",
      "Indicator Colors",
      "Flame Tests",
      "Sign of Reaction/Solution Enthalpy",
      "Activity Series",
      "Complex Formation",
      "Gas Production Reactions",
      "Common Precipitation Reactions",
      "Common Redox Reactions",
      "Acidity and Basicity of Compounds",
      "Common Reactions",
      "Hard Soft Acid Base Theory",
      "Solubility Rules",
      "Reaction Prediction",
      "Hydrides Reactions"
    ],
    "Laboratory or Analytical": [
      "Lab Equipment",
      "Lab Safety",
      "Primary Standards",
      "Indicator Selection",
      "Titrations",
      "Iodometry",
      "Redox Titrations",
      "Complexometry",
      "Back Titration",
      "Calorimetry",
      "Potentiometric Titration",
      "Coulometric Titration",
      "Reflux",
      "Recrystallization",
      "Distillation",
      "Liquid-Liquid Extraction",
      "Purity Analysis",
      "Gravimetric Analysis",
      "Chromatography",
      "Mixture Separation",
      "Error Analysis",
      "IR Spectroscopy",
      "NMR",
      "Mass Spectroscopy",
      "X-Ray Crystallography"
    ],
    "Thermodynamics": [
      "Heats of Formation",
      "First Law of Thermodynamics",
      "Second Law of Thermodynamics",
      "Third Law of Thermodynamics",
      "Work/Heat",
      "Internal Energy",
      "Enthalpy",
      "Entropy",
      "Molar Entropy Comparison",
      "Thermodynamic Efficiency",
      "Gibbs Free Energy",
      "Calorimetry Calculations",
      "Heat Capacity",
      "Spontaneity",
      "Thermodynamic Cycles",
      "Born-Haber Cycles",
      "Hess's Law",
      "Bond Dissociation Enthalpy Calculations",
      "Nonstandard Conditions",
      "Adiabatic Processes",
      "Isothermal Processes",
      "Isochoric Processes",
      "Isobaric Processes"
    ],
    "Kinetics": [
      "Determine Rate Law",
      "Factors Affecting Reaction Rate",
      "Determine Reaction Order",
      "Predict Reaction Mechanism",
      "Propose Reaction Mechanism",
      "Arrhenius Equation",
      "Reaction Coordinate Diagram of Catalysts",
      "Effect of Catalysts on Reaction Rate",
      "Radioactive Decay",
      "Collision Theory",
      "Transition State Theory",
      "Thermodynamics vs. Kinetics",
      "Steady-State Approximation",
      "Pre-Equilibrium Approximation",
      "Parallel Reactions",
      "Consecutive Reactions",
      "Reversible Reactions",
      "Lindemann Mechanism",
      "Michaelis Menten Kinetics",
      "Radical Reaction Kinetics",
      "Rate of Disappearance/Formation",
      "Calculate Half Life / Rate Constant",
      "Pseudo-Order Rate Law",
      "Nonlinear Rate Laws",
      "Determine Rate Law from Graph",
      "Determine Rate Law from Initial Rates"
    ],
    "Equilibrium": [
      "Solubility Calculations",
      "Equilibrium Constant",
      "Composition at Equilibrium",
      "Van't Hoff Equation",
      "Le Chatelier's Principle",
      "Common Ion Effect",
      "Complex Formation",
      "Spontaneity",
      "Mass Charge Balance Technique"
    ],
    "Acid-Base Chemistry": [
      "Monoprotic Strong Acid",
      "Monoprotic Weak Acid",
      "Polyprotic Acid",
      "Strong Base",
      "Weak Base",
      "pH Calculations",
      "pH of Salt Solution",
      "Percent Dissociation Calculations",
      "Amphoteric Species",
      "Mixture of Acid / Base",
      "Buffer Preparation / Calculation",
      "Amphiprotic Salt pH",
      "Henderson-Hasselbalch",
      "Fractional Composition",
      "Titration Curve Analysis",
      "Titration Calculations",
      "Leveling Effect",
      "Relative Acid/Base Strengths",
      "Acid-Base Equilibria",
      "Acid-Base Indicators",
      "Reactions of Common Bases",
      "Reactions of Common Acids"
    ],
    "Electrochemistry or Reduction-Oxidation": [
      "Oxidation State",
      "Activity Series",
      "Disproportionation",
      "Comproportionation",
      "Balancing Redox Reactions",
      "Redox Titrations",
      "Redox Indicators",
      "Nernst Equation",
      "Product Prediction",
      "Electrolysis",
      "Spontaneity",
      "Calculate Cell Potential",
      "Batteries / Fuel Cells",
      "Applications",
      "Latimer Diagrams",
      "Frost Diagrams",
      "Concentration Cells",
      "Ion Selective Electrodes",
      "Determine Anode / Cathode"
    ],
    "Organic Chemistry": [
      "Acidity / Basicity",
      "Resonance",
      "IMFs Strength",
      "Nomenclature",
      "Alkane",
      "Substitution",
      "Cycloalkanes",
      "Alkene",
      "Elimination",
      "Addition",
      "Alkyne",
      "Alcohol",
      "Conjugated Systems",
      "Aromatic Compounds",
      "Ethers and Epoxides",
      "Aldehydes and Ketones",
      "Alpha Carbon Reactions",
      "Aldol Condensation",
      "Radical Reactions",
      "Pericyclic Reactions",
      "Carboxylic Acid Derivatives",
      "Amine",
      "Heterocycles",
      "Isomers",
      "Stereochemistry",
      "Mechanisms",
      "Polymerization",
      "Conjugation",
      "Product Prediction",
      "Suggest Reagents",
      "Propose Mechanism",
      "Synthesis",
      "Structure Determination",
      "Count Bonds In Compound",
      "Organic Molecule IMF's"
    ],
    "Biochemistry": [
      "Structure of Biomolecules",
      "Common Biomolecules",
      "Carbohydrates",
      "Proteins",
      "Lipids",
      "DNA and RNA",
      "Protein Structure",
      "Amino Acid Acid Base Properties",
      "Biochemical Tests",
      "Enzymes"
    ],
    "Miscellaneous": [
      "Graph Analysis",
      "Applications in Real Life"
    ]
  },
  'competitions': {
    'USNCO': [
      'Locals',
      'Nationals Part I'
    ],
    'IChO': [
      'Theoretical'
    ]
  }
};

export default filterOptions;
import { Box, Center, useRadio } from '@chakra-ui/react';
import React from 'react';

const RadioCard = (props) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as='label' width='100%'>
      <input {...input} />
      <Center
        {...checkbox}
        cursor='pointer'
        borderWidth='1px'
        borderColor='gray.300'
        borderRadius='md'
        _checked={{
          bg: 'blue.500',
          color: 'white',
          borderColor: 'blue.600',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={5}
        height='40px'
      >
        {props.children}
      </Center>
    </Box>
  );
};

export default RadioCard;
import React, { useState, useEffect } from 'react';
import { Alert, AlertIcon, Box, Button, Divider, Fade, Flex, Heading, IconButton, Image, Input, Show, Stack, Tag, TagLabel, Text, Tooltip, useToast } from '@chakra-ui/react';
import { useParams, useSearchParams } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import ProblemSideBar from './ProblemSideBar';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import { HiOutlineMenu } from "react-icons/hi";
import SERVER_HOST from '../config';
import SignedOutModal from './SignedOutModal';
import InputBar from './InputBar';

const ProblemPage = ({ isSignedIn, setIsSignedIn }) => {
  // initial index is the index of the first problem that is clicked to get to the problems page
  // it is used as a reference point to fetch the previous / next page of problems, and never changes after the problem page is opened
  // for shuffled problems, the initial index is 0
  let { initIndex } = useParams();
  let [params] = useSearchParams();

  const competitionsParam = params.get('competitions');
  const yearStartParam = params.get('yearStart');
  const yearEndParam = params.get('yearEnd');
  const tagsParam = params.get('tags');
  const shuffledParam = params.get('shuffled');

  const filteredCompetitions = competitionsParam && competitionsParam != 'undefined' ? JSON.parse(decodeURIComponent(competitionsParam)) : [];
  const filteredYearRange = yearStartParam && yearEndParam && yearStartParam != 'undefined' && yearEndParam != 'undefined' ? [parseInt(yearStartParam), parseInt(yearEndParam)] : [];
  const filteredTags = tagsParam && tagsParam != 'undefined' ? JSON.parse(decodeURIComponent(tagsParam)) : [];
  const shuffled = shuffledParam == 'true'? true : false;

  const [problem, setProblem] = useState();
  // problem index is the index of the current problem being displayed, and changes when the user clicks the next / previous button
  const [problemIndex, setProblemIndex] = useState(Number(initIndex));
  const problemName = `${problem?.year} ${problem?.competition} ${problem?.round} #${problem?.question_number}`;
  const filterSz = 'sm';

  const [problemsCount, setProblemsCount] = useState(0);
  const [problems, setProblems] = useState([]);

  const [hideSideBar, setHideSideBar] = useState(false);
  const [answer, setAnswer] = useState();
  const toast = useToast();
  const [toastId, setToastId] = useState();
  // transition for showing correct / incorrect
  const [transitioning, setTransitioning] = useState(true);
  const [showSolution, setShowSolution] = useState(false);

  // For signed out modal
  const [isSignedOutModalOpen, setIsSignedOutModalOpen] = useState(false);
  const onCloseSignedOutModal = () => setIsSignedOutModalOpen(false);

  // Reset when problem changes
  useEffect(() => {
    setAnswer('');
    toast.close(toastId);
    setShowSolution(false);
  }, [problemIndex]);

  const [problemStatuses, setProblemStatuses] = useState(localStorage.getItem('problemStatuses') ? JSON.parse(localStorage.getItem('problemStatuses')) : {});
  useEffect(() => {
    console.log('update problem statuses2')
    if (!isSignedIn) {
      localStorage.setItem('problemStatuses', JSON.stringify(problemStatuses));
    } else {
      updateProblemStatuses(problemStatuses);
    }
  }, [problemStatuses]);

  const fetchProblemStatuses = async () => {
    if (isSignedIn) {
      console.log('fetching problem statuses...')
      const response = await fetch(`${SERVER_HOST}/problem-statuses`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      const data = await response.json();
      console.log('data from jwt', data)
      if (data.error && data.error == 'JWT expired') {
        console.log('session expired')
        localStorage.removeItem('token');
        setIsSignedOutModalOpen(true);
        setIsSignedIn(false);
        return;
      }
      setProblemStatuses(data || {});
    }
  };

  useEffect(() => {
    fetchProblemStatuses();
  }, [isSignedIn]);

  const handleSubmission = () => {
    console.log('submitted');
    console.log('answer:', answer, 'correct answer:', problem.problem_json.answer_key)
    const uppercaseAnswer = answer.toUpperCase();
    if (uppercaseAnswer != 'A' && uppercaseAnswer != 'B' && uppercaseAnswer != 'C' && uppercaseAnswer != 'D') {
      setToastId(toast({
        title: 'Answer Format Error',
        description: 'Please enter a valid answer (A, B, C, or D)',
        status: 'error',
        duration: 9000,
        isClosable: true,
      }));
      return;
    }
    if (uppercaseAnswer == problem.problem_json.answer_key) {
      const prevStatus = {...problemStatuses};
      prevStatus[problem.id] = 'correct';
      setProblemStatuses(prevStatus);
      setAnswer('');
    } else {
      const prevStatus = {...problemStatuses};
      prevStatus[problem.id] = 'incorrect';
      console.log('update problem statuses, ', problemStatuses, prevStatus);
      setProblemStatuses(prevStatus);
      setAnswer('');
    }
    setTransitioning(false);
    setTimeout(() => {
      setTransitioning(true);
    }, 200);
  };

  const handleShowSolution = () => {
    if (!showSolution) {
      setTimeout(() => {
        const element = document.getElementById('solution-divider');
        element.scrollIntoView({  behavior: 'smooth' });
      }, 200);
    }
    setShowSolution(!showSolution);
  }

  const updateProblemStatuses = async (problemStatuses) => {
    const response = await fetch(`${SERVER_HOST}/update-problem-statuses`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ problemStatuses }),
    });

    const data = await response.json();
    console.log('data from jwt', data)
    if (data.error && data.error == 'JWT expired') {
      console.log('session expired')
      localStorage.removeItem('token');
      setIsSignedOutModalOpen(true);
      setIsSignedIn(false);
    }

    if (!response.ok) {
      console.log('Error updating problem statuses:');
    }
  };

  console.log('problem', problem)

  const [nextPage, setNextPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);

  const [shuffledArray, setShuffledArray] = useState(() => {
    const saved = localStorage.getItem('shuffledArray');
    const initialValue = saved != 'undefined' && saved ? JSON.parse(saved) : undefined;
    return initialValue;
  });

  const [loading, setLoading] = useState(false);

  const fetchProblems = async (page) => {
    setLoading(true);
    try {
      console.log('fetching problems...');
      let response;
      console.log(`page: ${page}, year range: ${filteredYearRange}, competitions: ${JSON.stringify(filteredCompetitions)}, tags: ${JSON.stringify(filteredTags)}, offset=${initIndex}`);
      const urlTags = encodeURIComponent(JSON.stringify(filteredTags));
      const urlCompetitions = encodeURIComponent(JSON.stringify(filteredCompetitions));

      if (!shuffled) {
        response = await fetch(`${SERVER_HOST}/problems?limit=20&page=${page}&offset=${initIndex}&competitions=${urlCompetitions}&yearStart=${filteredYearRange[0]}&yearEnd=${filteredYearRange[1]}&tags=${urlTags}`);  
      } else {
        const shuffleIndices = shuffledArray.slice((page - 1) * 20, Math.min(page * 20, shuffledArray.length));
        const urlShuffleIndices = encodeURIComponent(JSON.stringify(shuffleIndices));
        response = await fetch(`${SERVER_HOST}/problems?offset=${(page - 1) * 20}&competitions=${urlCompetitions}&yearStart=${filteredYearRange[0]}&yearEnd=${filteredYearRange[1]}&tags=${urlTags}&shuffled=true&shuffleIndices=${urlShuffleIndices}`);
      }
      const data = await response.json();
      console.log('data', data);
      const fetchedProblems = data.problems;
      const count = parseInt(data.count, 10);

      if (page === 1) {
        setProblems(fetchedProblems);
        setProblem(fetchedProblems[problemIndex - fetchedProblems[0].index]);
      } else if (page > 1) {
        setProblems(prevProblems => [...prevProblems, ...fetchedProblems]);
        setProblem([...problems, ...fetchedProblems][problemIndex - problems[0].index]);
      } else {
        setProblems(prevProblems => [...fetchedProblems, ...prevProblems]);
        setProblem([...fetchedProblems, ...problems][problemIndex - fetchedProblems[0].index]);
      }
      setProblemsCount(count);
    }
    catch (err) {
      console.log('Error fetching problems:', err);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchProblems(nextPage);
  }, [nextPage]);

  useEffect(() => {
    if (previousPage == 1) return;
    fetchProblems(previousPage);
  }, [previousPage]);

  const loadMorePrev = () => {
    setNextPage(prevPage => prevPage - 1);
  };

  const loadMoreNext = () => {
    setPreviousPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    console.log('set problem')
    if (!problems || problems.length === 0) {
      return;
    }
    // load more problems if used arrow keys to navigate to a problem that is not loaded
    if (problemIndex - problems[0].index < 0) {
      loadMorePrev();
    }
    if (problemIndex - problems[0].index >= problems.length) {
      loadMoreNext();
    }

    setProblem(problems[problemIndex - problems[0].index]);
  }, [problemIndex]);

  return (
    <Stack direction='row'>
      <Show above='md'>
        <Box position='relative'>
          <IconButton
            colorScheme='blue' 
            variant='outline'
            icon={<HiOutlineMenu />} 
            position='absolute'
            left={hideSideBar ? '10px' : '390px'}
            top='10px'
            zIndex='1'
            onClick={() => setHideSideBar(!hideSideBar)}
          />
          {!hideSideBar && (
            <ProblemSideBar
              initIndex={initIndex}
              params={params}
              filterSz={filterSz}
              problems={problems}
              problemsCount={problemsCount}
              problemIndex={problemIndex}
              setProblemIndex={setProblemIndex}
              problemStatuses={problemStatuses}
              loadMorePrev={loadMorePrev}
              loadMoreNext={loadMoreNext}
              loading={loading}
            />
          )}
        </Box>
      </Show>
      <Stack align='center' mx='auto' gap={3} width='100%' px={5} py={6}>
        {problem && (
          <>
            <Flex 
              justify='space-between' 
              align='center'
              width='100%' 
              maxW='container.lg' 
              gap={3} 
              px={{base: 2, md: 10}} 
              mb={{ base: 2, lg: 5 }}
            >
              <Button 
                isDisabled={problem.index == 0} 
                onClick={() => setProblemIndex(prevIndex => prevIndex - 1)}
                size={{ base: 'sm', sm: 'md' }}
              >
                <FaChevronLeft />
              </Button>
              <Heading textAlign='center' size={{ base: 'lg', sm: 'xl' }}>{problemName}</Heading>
              <Button 
                isDisabled={problemIndex == problemsCount - 1}
                onClick={() => setProblemIndex(prevIndex => prevIndex + 1)}
                size={{ base: 'sm', sm: 'md' }}
              >
                <FaChevronRight />
              </Button>
            </Flex>
            {problem.problem_json.tags && Object.keys(problem.problem_json.tags).length > 0 && (
              <Stack direction='row' wrap='wrap' align='center' mb={3}>
                {Object.keys(problem.problem_json.tags).map((category) => {
                  return (
                  <React.Fragment key={category}>
                    <Tag 
                      size={filterSz}
                      minW='min-content' 
                      height='1.5em' 
                      whiteSpace='nowrap' 
                      borderRadius='full'
                      variant='solid'
                      colorScheme='blue'
                    >
                      <TagLabel>{category}</TagLabel>
                    </Tag>
                    {problem.problem_json.tags[category].map(tag => (
                      <Tag 
                        key={tag} 
                        size={filterSz}
                        colorScheme='blue' 
                        minW='min-content' 
                        height='1.5em'
                        whiteSpace='nowrap'
                        borderRadius='full'
                      >
                        <TagLabel>{tag}</TagLabel>
                      </Tag>
                    ))}
                  </React.Fragment>
                )})}
              </Stack>
            )}
            <Box p={2} borderWidth='1px' borderColor='blue.500' borderRadius='xl' maxW='container.md'>
              <Image src={`https://storage.googleapis.com/chemhub-images/${problem.competition.split(' ').join('-')}/${problem.round ? problem.round.split(' ').join('-') : 'Theoretical'}/${problem.year}/${problem.question_number}.jpg`} />
            </Box>
            <InputBar
              problem={problem}
              handleSubmission={handleSubmission}
              handleShowSolution={handleShowSolution}
              answer={answer}
              setAnswer={setAnswer}
              showSolution={showSolution}
            />
            {problemStatuses[problem.id] && problemStatuses[problem.id] == 'correct' && (
              <Box width='100%' maxW='container.md'>
                <Fade in={transitioning}>
                  <Alert status='success'>
                    <AlertIcon />
                    Your Answer was Correct
                  </Alert>
                </Fade>
              </Box>
            )}
            {problemStatuses[problem.id] && problemStatuses[problem.id] == 'incorrect' && (
              <Box width='100%' maxW='container.md'>
                <Fade in={transitioning}>
                  <Alert status='error'>
                    <AlertIcon />
                    Your Answer was Incorrect
                  </Alert>
                </Fade>
              </Box>
            )}
            {showSolution && (
              <Box>
                <Divider my={3} id='solution-divider'/>
                <Heading textAlign='center' mb={3}>Solution</Heading>
                <Box p={2} borderWidth='1px' borderColor='blue.500' borderRadius='xl' maxW='container.md'>
                  <Image src={`https://storage.googleapis.com/chemhub-images/solutions/${problem.competition.split(' ').join('-')}/${problem.round ? problem.round.split(' ').join('-') : 'Theoretical'}/${problem.year}/${problem.question_number}.jpg`} />
                </Box>
              </Box>
            )}
          </>
        )}
        {!problem && (
          <LoadingSpinner />
        )}
        <Show below='md'>
          <ProblemSideBar
            initIndex={initIndex}
            params={params}
            filterSz={filterSz}
            problems={problems}
            problemsCount={problemsCount}
            problemIndex={problemIndex}
            setProblemIndex={setProblemIndex}
            problemStatuses={problemStatuses}
            loadMorePrev={loadMorePrev}
            loadMoreNext={loadMoreNext}
            loading={loading}
          />
        </Show>
      </Stack>
      <SignedOutModal isOpen={isSignedOutModalOpen} onClose={onCloseSignedOutModal} />
    </Stack>
  );
};

export default ProblemPage;
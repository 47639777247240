import React, { useEffect, useState } from 'react';
import { Link as ChakraLink, Table, TableContainer, Tr, Th, Td, Thead, Tbody, Tag, Stack, Box, Tooltip, TagLabel, TagCloseButton, Flex, Text, Button, Show, Icon, IconButton, Drawer, DrawerOverlay, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, useDisclosure, ModalCloseButton, ModalHeader, Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom'
import SERVER_HOST from '../config';
import FilterBar from './FilterBar';
import { FaRegCircleXmark, FaShuffle } from 'react-icons/fa6';
import { FaInfoCircle, FaRegCheckCircle, FaFilter } from 'react-icons/fa';
import { MdOutlineCircle } from 'react-icons/md';
import SignedOutModal from './SignedOutModal';


const HomePage = ({ isSignedIn, setIsSignedIn }) => {
  const [problems, setProblems] = useState([]);
  const [problemCount, setProblemCount] = useState(0);

  // For filter drawer on small devices
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const [isSignedOutModalOpen, setIsSignedOutModalOpen] = useState(false);
  const onCloseSignedOutModal = () => setIsSignedOutModalOpen(false);

  const [page, setPage] = useState(1);

  const [competitions, setCompetitions] = useState(() => {
    const saved = sessionStorage.getItem('competitions');
    const initialValue = saved != 'undefined' && saved ? JSON.parse(saved) : undefined;
    return initialValue;
  });
  useEffect(() => {
    sessionStorage.setItem('competitions', JSON.stringify(competitions));
  }, [competitions]);
   
  const [yearRange, setYearRange] = useState(() => {
    const saved = sessionStorage.getItem('yearRange');
    const initialValue = saved != 'undefined' && saved ? JSON.parse(saved) : [1900, 2024];
    return initialValue;
  });
  useEffect(() => {
    sessionStorage.setItem('yearRange', JSON.stringify(yearRange));
  }, [yearRange]);
  
  const [tags, setTags] = useState(() => {
    const saved = sessionStorage.getItem('tags');
    const initialValue = saved != 'undefined' && saved ? JSON.parse(saved) : undefined;
    return initialValue;
  });
  useEffect(() => {
    sessionStorage.setItem('tags', JSON.stringify(tags));
  }, [tags]);

  const filterSz = 'sm';

  const reformatTag = (tag) => {
    const words = tag.split(/[ _]/);
    const capitalizedWords = words.map((word) => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1);
      return firstLetter + restOfWord;
    });
    return capitalizedWords.join(' ');
  };

  const loadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  const fetchProblems = async () => {
    try {
      console.log('fetching problems...');
      console.log(`page: ${page}, year range: ${yearRange}, competitions: ${JSON.stringify(competitions)}, tags: ${JSON.stringify(tags)}`);
      const urlTags = encodeURIComponent(JSON.stringify(tags));
      const urlCompetitions = encodeURIComponent(JSON.stringify(competitions));
      const response = await fetch(`${SERVER_HOST}/problems?limit=50&page=${page}&competitions=${urlCompetitions}&yearStart=${yearRange[0]}&yearEnd=${yearRange[1]}&tags=${urlTags}`);

      const data = await response.json();
      console.log('data', data);
      const fetchedProblems = data.problems;
      const count = parseInt(data.count, 10);

      if (page === 1) {
        setProblems(fetchedProblems);
      }
      else {
        setProblems(prevProblems => [...prevProblems, ...fetchedProblems]);
      }
      setProblemCount(count);
    }
    catch (err) {
      console.log('Error fetching problems:', err);
    }
  }

  useEffect(() => {
    fetchProblems(page);
  }, [page]);

  useEffect(() => {
    setPage(1);
    fetchProblems(1);
  }, [tags, yearRange, competitions]);

  // for sorting
  const handleClickName = () => {

  };

  const [problemStatuses, setProblemStatuses] = useState(localStorage.getItem('problemStatuses') ? JSON.parse(localStorage.getItem('problemStatuses')) : {});

  const fetchProblemStatuses = async () => {
    if (isSignedIn) {
      console.log('fetching problem statuses...')
      const response = await fetch(`${SERVER_HOST}/problem-statuses`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      const data = await response.json();
      console.log('data from jwt', data)
      if (data.error && data.error == 'JWT expired') {
        console.log('session expired')
        localStorage.removeItem('token');
        setIsSignedOutModalOpen(true);
        setIsSignedIn(false);
        return;
      }
      setProblemStatuses(data || {});
    }
  };

  useEffect(() => {
    fetchProblemStatuses();
  }, [isSignedIn]);

  const handleShuffle = () => {
    const shuffledArray = Array.from({ length: problemCount }, (_, index) => index);
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    localStorage.setItem('shuffledArray', JSON.stringify(shuffledArray));
    window.open(`/problem/0?competitions=${encodeURIComponent(JSON.stringify(competitions))}&yearStart=${yearRange[0]}&yearEnd=${yearRange[1]}&tags=${encodeURIComponent(JSON.stringify(tags))}&shuffled=true`, '_blank');
  };
  


  return (
    <>
      <Flex direction='row' gap={4} px={8} pt={{ base: 2, md: 6 }} maxW='container.xl' mx='auto'>
        <Show above='md'>
          <Box 
            position='sticky' 
            top={6} 
            flexShrink='0' 
            maxHeight='calc(100vh - 30px)' 
            overflow='scroll' 
            pr={4} 
            width='40%'
            maxW='md'
          >
            <FilterBar 
              competitions={competitions}
              setCompetitions={setCompetitions}
              yearRange={yearRange}
              setYearRange={setYearRange}
              tags={tags}
              setTags={setTags}
              filterSz={filterSz}
            />
          </Box>
        </Show>
        <Box flexGrow={1} pr={4} pb={3} minW='0'>
          <Stack direction='column' gap={2}>
            <Flex direction='row' justify='space-between' align='center' mt={2}>
              <Text mr={5}>Showing {problems.length} of {problemCount} Problems</Text>
              <Stack direction='row' gap={4}>
                <Show below='md'>
                  <IconButton 
                    ref={btnRef} 
                    icon={<FaFilter />} 
                    colorScheme='blue' 
                    size={filterSz} 
                    onClick={onOpen}
                  />
                  <Drawer
                    isOpen={isOpen}
                    placement='top'
                    onClose={onClose}
                    finalFocusRef={btnRef}
                    size='full'
                    allowPinchZoom
                  >
                    <DrawerOverlay />
                    <DrawerContent>
                      <DrawerCloseButton />
                      <DrawerHeader>Filters</DrawerHeader>
                      <DrawerBody>
                        <FilterBar 
                          competitions={competitions}
                          setCompetitions={setCompetitions}
                          yearRange={yearRange}
                          setYearRange={setYearRange}
                          tags={tags}
                          setTags={setTags}
                          filterSz={filterSz}
                        />
                      </DrawerBody>
                    </DrawerContent>
                  </Drawer>
                </Show>
                <Button colorScheme='blue' size={filterSz} onClick={handleShuffle}>
                  <Stack direction='row' align='center' justify='center'>
                    <Show above='md'>
                      <Text>Random</Text>
                    </Show>
                    <FaShuffle />
                  </Stack>
                </Button>
              </Stack>
            </Flex>
            <Stack direction='row' wrap='wrap' align='center'>
              {tags && Object.keys(tags).map((category) => (
                <React.Fragment key={category}>
                  {tags[category].map(tag => (
                    <Tag 
                      key={tag} 
                      size={filterSz}
                      colorScheme='blue' 
                      minW='min-content' 
                      height='1.5em'
                      whiteSpace='nowrap'
                      borderRadius='full'
                    >
                      <TagLabel>{tag}</TagLabel>
                      <TagCloseButton 
                        color='black' 
                        onClick={() => {
                          let newTags = JSON.parse(JSON.stringify(tags));
                          newTags[category].splice(newTags[category].indexOf(tag), 1);
                          if (newTags[category].length == 0) {
                            delete newTags[category];
                          }
                          setTags(newTags);
                        }}
                      />
                    </Tag>
                  ))}
                </React.Fragment>
              ))}
            </Stack>
            <TableContainer>
              <Table variant='simple' colorScheme='blue'>
                <Thead>
                  <Tr>
                    <Th>
                      <Tooltip label={`Indicates if a problem is correct, incorrect or unattempted`} placement='auto'>
                        <Stack direction='row' align='center'>
                          <Text>Status</Text>
                          <Icon as={FaInfoCircle} color='blue.500' />
                        </Stack>
                      </Tooltip>
                    </Th>
                    <Th width='30%' onClick={handleClickName}>Problem Name</Th>
                    <Th>Tags</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {problems && problems.map(problem => {
                    const problemName = `${problem.year} ${problem.competition} ${problem.round} #${problem.question_number}`;
                    let iconColor;
                    let iconSymbol;
                    if (!problemStatuses[problem.id] || problemStatuses[problem.id] == 'incomplete') {
                      iconColor = 'gray.300';
                      iconSymbol = MdOutlineCircle;
                    }
                    else if (problemStatuses[problem.id] == 'correct' ) {
                      iconColor = 'green.500';
                      iconSymbol = FaRegCheckCircle;
                    } else {
                      iconColor = 'red.500';
                      iconSymbol = FaRegCircleXmark;
                    }
                    return (
                      <Tr key={problem.id}>
                        <Td>
                          <Icon as={iconSymbol} color={iconColor} ml={4} />
                        </Td>
                        <Td>
                          <ChakraLink
                            as={RouterLink} 
                            to={`/problem/${problem.index}?competitions=${encodeURIComponent(JSON.stringify(competitions))}&yearStart=${yearRange[0]}&yearEnd=${yearRange[1]}&tags=${encodeURIComponent(JSON.stringify(tags))}&shuffled=false`}
                            _hover={{ textDecoration: 'underline #3182CE' }}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {problemName}
                          </ChakraLink>
                        </Td>
                        <Td>
                          <Stack direction='row'>
                            {problem.problem_json.tags && Object.keys(problem.problem_json.tags).map(category => (
                              <React.Fragment key={category}>
                                <Tag key={category} size={filterSz} colorScheme='blue' variant='solid' borderRadius='full'>
                                  {reformatTag(category)}
                                </Tag>
                                {problem.problem_json.tags[category].length > 0 && problem.problem_json.tags[category].map(subcategory => (
                                  <Tag key={subcategory} size={filterSz} colorScheme='blue' borderRadius='full'>
                                    {reformatTag(subcategory)}
                                  </Tag>
                                ))}
                              </React.Fragment>
                            ))}
                          </Stack>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            {problems.length !== problemCount ? (
              <Button 
                alignSelf='center' 
                size={filterSz} 
                colorScheme='blue' 
                onClick={loadMore} 
                width='100px'
                mt={1}
              >
                Load More
              </Button>
            ) : null}
          </Stack>
        </Box>
      </Flex>
      <SignedOutModal isOpen={isSignedOutModalOpen} onClose={onCloseSignedOutModal} />
    </>
  );
};

export default HomePage;
import React, { useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import Fuse from 'fuse.js';
import _ from 'lodash';
import { Box, Flex, Heading, Input, InputGroup, InputLeftElement, Stack, Tag, TagLabel, Text } from '@chakra-ui/react';

const Filter = ({ filterSz, tagSz, tagBorderRadius, filterName, filterItems, setFilterItems, options }) => {
  const [searched, setSearched] = useState(options);

  const debouncedOnChange = _.debounce(val => {
    if (val.trim()) {
      console.log(`Searched ${filterName}!`);
      // Flatten options into an array of category-subcategory pairs
      const pairs = Object.entries(options).flatMap(([category, subcategories]) => 
        subcategories.map(subcategory => ({ category, subcategory }))
      );
  
      // Perform the fuzzy search on the pairs array
      const fuse = new Fuse(pairs, {
        keys: ['category', 'subcategory'],
        threshold: 0.3,
      });
      const result = fuse.search(val);
  
      // Transform the search results back into the original data structure
      const newSearched = result.reduce((acc, { item }) => {
        const { category, subcategory } = item;
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(subcategory);
        return acc;
      }, {});
      setSearched(newSearched);
    }
    else {
      setSearched(options);
    }
  }, 300);

  return (
    <Box px={1}>
      <Flex direction='row' align='center' justify='space-between'>
        <Heading size={filterSz} mb={2}>{filterName}</Heading>
        <Text 
          textColor='blue.500' 
          fontWeight='medium' 
          mb={2} 
          onClick={() => setFilterItems({})}
          _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          Clear all
        </Text>
      </Flex>
      <InputGroup size={filterSz}>
        <InputLeftElement pointerEvents='none' children={<IoIosSearch />} />
        <Input placeholder={`Search for ${filterName}`} borderRadius='full' onChange={(e) => debouncedOnChange(e.target.value)}/>
      </InputGroup>
      <Stack direction='column' gap={5} mt={3}>
        {searched && Object.keys(searched).map((category) => {
          let allSelected = true;
          for (let option of searched[category]) {
            if (!filterItems || !Object.keys(filterItems).includes(category) || !filterItems[category].includes(option)) {
              allSelected = false;
              break;
            }
          }
          return (
            <Stack key={category} direction='column'>
              <Tag 
                variant='solid' 
                size={tagSz} 
                colorScheme={allSelected ? 'blue' : 'gray'} 
                borderRadius={tagBorderRadius}
                maxW='max-content'
                cursor='pointer'
                onClick={() => {
                  let newFilterItems;
                  if (!filterItems) {
                    newFilterItems = {[category]: searched[category]};
                  }
                  else {
                    newFilterItems = JSON.parse(JSON.stringify(filterItems));
                    if (Object.keys(filterItems).includes(category) && newFilterItems[category].length == options[category].length) {
                      delete newFilterItems[category];
                    }
                    else {
                      newFilterItems[category] = searched[category];
                    }
                  }
                  setFilterItems(newFilterItems);
                }}
              >
                <TagLabel>{category}</TagLabel>
              </Tag>
              <Flex direction='row' align='stretch' ml={1} gap={2}>
                <Box borderColor='gray.400' alignSelf='stretch' width='1px' borderWidth='0.5px'/>
                <Stack direction='row' wrap='wrap'>
                  {searched[category].map((subcategory) => {
                    const filterItemIncluded = filterItems && Object.keys(filterItems).includes(category) && filterItems[category].includes(subcategory);
                    return (
                      <Tag 
                        key={subcategory} 
                        size={tagSz}
                        colorScheme={filterItemIncluded ? 'blue' : 'gray'} 
                        borderRadius={tagBorderRadius} 
                        maxW='max-content'
                        cursor='pointer'
                        onClick={() => {
                          let newFilterItems;
                          if (!filterItems) {
                            newFilterItems = {[category]: [subcategory]};
                          }
                          else {
                            newFilterItems = JSON.parse(JSON.stringify(filterItems));
                            console.log('???', filterName, 'filterItems', filterItems)
                            console.log('filterItems', JSON.stringify(filterItems))
                            if (filterItems && Object.keys(filterItems).includes(category) && filterItems[category].includes(subcategory)) {
                              console.log('old filterItems', JSON.stringify(filterItems));
                              newFilterItems[category].splice(newFilterItems[category].indexOf(subcategory), 1);
                              if (newFilterItems[category].length === 0) {
                                delete newFilterItems[category];
                              }
                            } 
                            else if  (!Object.keys(filterItems).includes(category)) {
                              newFilterItems[category] = [subcategory];
                            }
                            else {
                              newFilterItems[category].push(subcategory);
                            }
                          }
                          setFilterItems(newFilterItems);
                        }}
                      >
                        <TagLabel>{subcategory}</TagLabel>
                      </Tag>
                    )
                  })}
                </Stack>
              </Flex>
            </Stack>
          );
        })}
        {(!searched || Object.keys(searched).length == 0) && (
          <Text mx='auto'>No Result</Text>
        )}
      </Stack>
    </Box>
  );
};

export default Filter;
import React from 'react';
import { Stack, Heading, Text, Divider, Tag, TableContainer, Table, Tbody, Tr, Td, Box, IconButton, Icon, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Flex } from '@chakra-ui/react';
import { FaChevronUp, FaChevronDown, FaRegCheckCircle } from 'react-icons/fa';
import { MdOutlineCircle } from 'react-icons/md';
import { FaRegCircleXmark } from 'react-icons/fa6';

const ProblemSideBar = ({ 
  initIndex, 
  params, 
  filterSz,  
  problems, 
  problemsCount, 
  problemIndex, 
  setProblemIndex,
  problemStatuses,
  loadMorePrev,
  loadMoreNext,
  loading
}) => {
  const competitionsParam = params.get('competitions');
  const yearStartParam = params.get('yearStart');
  const yearEndParam = params.get('yearEnd');
  const tagsParam = params.get('tags');
  const shuffledParam = params.get('shuffled');

  console.log('rerendering side bar, initIndex:', initIndex, 'problemIndex', problemIndex);

  const filteredCompetitions = competitionsParam && competitionsParam != 'undefined' ? JSON.parse(decodeURIComponent(competitionsParam)) : [];
  const filteredYearRange = yearStartParam && yearEndParam && yearStartParam != 'undefined' && yearEndParam != 'undefined' ? [parseInt(yearStartParam), parseInt(yearEndParam)] : [];
  const filteredTags = tagsParam && tagsParam != 'undefined' ? JSON.parse(decodeURIComponent(tagsParam)) : [];
  const shuffled = shuffledParam == 'true'? true : false;

  return (
    <Stack 
      direction='column' 
      bg={{ base: '', md: 'gray.50' }} 
      gap={3} 
      px={6} 
      py={4} 
      height='calc(100vh - 100px)' 
      width={{ base: '100%', md: 'sm' }}
    >
      <Accordion allowToggle borderColor='gray.400'>
        <AccordionItem>
          <AccordionButton px={2}>
            <Box as='span' flex='1' textAlign='left'>
              <Heading size='sm'>Selected Filters</Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel px={2}>
            <Stack direction='column' gap={3}>
              <Stack direction='row' ml={3}>
                {filteredYearRange && filteredYearRange.length > 0 && (
                  <>
                    <Text>From</Text>
                    <Text color='blue.500' fontWeight='bold'>{filteredYearRange ? filteredYearRange[0] : 'undefined'} </Text>
                    <Text>to</Text>
                    <Text color='blue.500' fontWeight='bold'>{filteredYearRange ? filteredYearRange[1] : 'undefined'}</Text>
                  </>
                )}
                {(!filteredYearRange || filteredYearRange.length === 0) && (
                  <Text color='blue.500' fontWeight='bold'>All Years</Text>
                )}
              </Stack>
              <Divider borderColor='gray.300' />
              <Stack direction='row' wrap='wrap' ml={3}>
                {filteredTags && Object.keys(filteredTags).map(category => (
                  <React.Fragment key={category}>
                    {filteredTags[category].map(tag => (
                      <Tag key={tag} size={filterSz} colorScheme='blue' maxW='fit-content'>{tag}</Tag>
                    ))}
                  </React.Fragment>
                ))}
                {(!filteredTags || Object.keys(filteredTags).length === 0) && (
                  <Tag size={filterSz} colorScheme='blue' maxW='fit-content'>All Tags</Tag>
                )}
              </Stack>
              <Divider borderColor='gray.300' />
              <Stack direction='row' wrap='wrap' ml={3}>
                {filteredCompetitions && Object.keys(filteredCompetitions).map(competition => (
                  <React.Fragment key={competition}>
                    {filteredCompetitions[competition] && filteredCompetitions[competition].map(round => (
                      <Tag key={round} size={filterSz} colorScheme='blue' maxW='fit-content'>{competition} {round}</Tag>
                    ))}
                  </React.Fragment>
                ))}
                {(!filteredCompetitions || filteredCompetitions.length === 0) && (
                  <Tag size={filterSz} colorScheme='blue' maxW='fit-content'>All Competitions</Tag>
                )}
              </Stack>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Heading size='sm' ml={2}>Problems</Heading>
      <Box
        p={2} 
        borderRadius='lg' 
        border='1px' 
        borderColor='blue.500'
        overflowY='auto' 
        height='100%'
        ml={3}
      >
        {problems && problems.length > 0 && problems[0].index != 0 && (
          <IconButton 
            size='md' 
            height='30px' 
            variant='ghost' 
            colorScheme='blue' 
            aria-label='Load more problems' 
            icon={<FaChevronUp />} 
            _hover={{ bg: 'blue.100' }}
            width='100%'
            mb={1}
            onClick={loadMorePrev}
            isLoading={loading}
          />
        )}
        <TableContainer position='relative' >
          <Table size='md' colorScheme='blue' className='tableWithShadow'>
            <Tbody>
              {problems && problems.map(problem => {
                const problemName = `${problem.year} ${problem.competition} ${problem.round} #${problem.question_number}`;
                let iconColor;
                let iconSymbol;
                if (!problemStatuses[problem.id] || problemStatuses[problem.id] == 'incomplete') {
                  iconColor = 'gray.500';
                  iconSymbol = MdOutlineCircle;
                }
                else if (problemStatuses[problem.id] == 'correct' ) {
                  iconColor = 'green.500';
                  iconSymbol = FaRegCheckCircle;
                } else {
                  iconColor = 'red.500';
                  iconSymbol = FaRegCircleXmark;
                }
                return (
                  <Tr key={problem.index}>
                    <Td px={2} py={3} bg={problem.index == problemIndex ? 'blue.100' : ''}>
                      <Stack 
                        direction='horizontal' 
                        align='center' 
                        gap={2} 
                        onClick={() => setProblemIndex(problem.index)}
                        _hover={{ textDecoration: 'underline #3182CE', cursor: 'pointer' }}
                      >
                        <Icon 
                          as={iconSymbol} 
                          color={iconColor} 
                        /> 
                        <Text>{problemName}</Text>
                      </Stack>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        {problems && problems.length > 0 && ((!shuffled && problems[problems.length - 1].index != problemsCount - 1) || (shuffled && problems.length != problemsCount)) && (
          <IconButton 
            size='md' 
            height='30px' 
            variant='ghost' 
            colorScheme='blue' 
            aria-label='Load more problems' 
            icon={<FaChevronDown />} 
            _hover={{ bg: 'blue.100' }}
            width='100%'
            mt={1}
            onClick={loadMoreNext}
            isLoading={loading}
          />
        )}
      </Box>
    </Stack>
  );
};

export default ProblemSideBar;
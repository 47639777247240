import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Tag, TagLabel, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import filterOptions from '../filterOptions';
import { Form, Formik, Field } from 'formik';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteGroup,
  AutoCompleteTag,
} from '@choc-ui/chakra-autocomplete';
import Fuse from 'fuse.js';
import _ from 'lodash';

const AboutPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const validateYear = (year) => {
    let error;
    if (!year) {
      error = 'Year is required';
    } else if (year < 1800 || year > 2024) {
      error = 'Year must be between 1800 and 2024';
    } 
    return error;
  }
  const validateQNum = (qNum) => {
    let error;
    if (!qNum) {
      error = 'Question number is required';
    } 
    return error;
  }
  const validateCompetition = (competition) => {
    let error;
    if (!competition) {
      error = 'Competition is required';
    }
    return error;
  }
  const validateRound = (round) => {
    let error;
    if (!round) {
      error = 'Round is required';
    }
    return error;
  }
  const validateProblem = (problem) => {
    let error;
    if (!problem) {
      error = 'Problem is required';
    }
    return error;
  }
  const validateTags = (tags) => {
    let error;
    if (!tags) {
      error = 'Tags are required';
    }
    return error;
  }

  return (
    <Box maxW='container.md' px={5} py={3} mx='auto'>
      <Heading my={5}>Contribute a Problem</Heading>
      <Alert>
        <AlertIcon />
        <AlertDescription>
          We are current working on _______ problems. These will likely to be added to the website by June. This form is only for original problems and problems not on our todo list.
        </AlertDescription>
      </Alert>
      <Formik
        initialValues={{}} 
        onSubmit={(values, actions) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2))
            actions.setSubmitting(false)
          }, 1000)
        }}
      >
        {({ handleSubmit, handleBlur, setFieldValue, ...other }) => (
          <Form>
            <Stack direction='column' gap={3} mt={5}>
              <Flex direction='row' gap={5}>
                <Field name='year' validate={validateYear}>
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.year && form.touched.year}>
                      <FormLabel>Year</FormLabel>
                      <Input {...field} placeholder='Year' />
                      <FormErrorMessage>{form.errors.year}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name='qNum' validate={validateQNum}>
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.qNum && form.touched.qNum}>
                      <FormLabel>Question Number</FormLabel>
                      <Input {...field} placeholder='Question Number' />
                      <FormErrorMessage>{form.errors.qNum}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Flex>
              <Field name='competition' validate={validateCompetition}>
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.competition && form.touched.competition}>
                    <FormLabel>Competition</FormLabel>
                    <AutoComplete
                      onChange={val => setFieldValue('competition', val)}
                      openOnFocus
                      rollNavigation
                      listAllValuesOnFocus
                      freeSolo
                      value={other.values.team}
                    >
                      <AutoCompleteInput
                        variant='filled'
                        name='competition'
                        onBlur={handleBlur}
                      />
                      <AutoCompleteList>
                        <AutoCompleteGroup>
                          {Object.keys(filterOptions.competitions).map(option => (
                            <AutoCompleteItem
                              key={`option-${option}`}
                              value={{
                                title: `${option}`
                              }}
                              label={option}
                              textTransform='capitalize'
                            />
                          ))}
                        </AutoCompleteGroup>
                      </AutoCompleteList>
                    </AutoComplete>
                    <FormErrorMessage>{form.errors.competition}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name='round' validate={validateRound}>
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.round && form.touched.round}>
                    <FormLabel>Round</FormLabel>
                    <AutoComplete
                      onChange={val => setFieldValue('round', val)}
                      openOnFocus
                      rollNavigation
                      listAllValuesOnFocus
                      freeSolo
                    >
                      <AutoCompleteInput
                        variant='filled'
                        name='round'
                        onBlur={handleBlur}
                      />
                      <AutoCompleteList>
                        <AutoCompleteGroup>
                          {other.values.competition && other.values.competition != '' && (
                            <>
                              {filterOptions.competitions[other.values.competition].map(round => (
                                <AutoCompleteItem
                                  key={`option-${round}`}
                                  value={{
                                    title: `${round}`
                                  }}
                                  label={round}
                                  textTransform='capitalize'
                                />
                              ))}
                              <AutoCompleteItem
                                value={{
                                  title: 'New Round'
                                }}
                                onClick={onOpen}
                              />
                            </>
                          )}
                          {(!other.values.competition || other.values.competition == '') && (
                            <AutoCompleteItem
                              disabled
                              value={{
                                title: 'Select a competition first'
                              }}
                              label='Select a competition first'
                            />
                          )}
                        </AutoCompleteGroup>
                      </AutoCompleteList>
                    </AutoComplete>
                    <FormErrorMessage>{form.errors.round}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name='problem' validate={validateProblem}>
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.problem && form.touched.problem}>
                    <FormLabel>Problem</FormLabel>
                    <Input {...field} placeholder='Problem' />
                    <FormErrorMessage>{form.errors.problem}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name='tags' validate={validateTags}>
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.tags && form.touched.tags}>
                    <FormLabel>Tags</FormLabel>
                    <AutoComplete
                      multiple
                      onChange={val => {
                        console.log('val', val)
                        setFieldValue('tags', val)
                      }}
                      openOnFocus
                      rollNavigation
                      listAllValuesOnFocus
                      freeSolo
                    >
                      <AutoCompleteInput variant='filled'>
                        {({ tags }) => 
                          [...tags].sort((a, b) => a.label.localeCompare(b.label)).map((tag, tid) => {
                            const isSubCat = tag.label.split('-').length == 2;
                            return (
                              <AutoCompleteTag
                                colorScheme='blue'
                                variant={isSubCat ? 'subtle' : 'solid'}
                                borderRadius='full'
                                maxW='max-content'
                                key={tid}
                                label={isSubCat ? tag.label.split('-')[1] : tag.label}
                                onRemove={tag.onRemove}
                              />
                            );
                          })
                        }
                      </AutoCompleteInput>
                      <AutoCompleteList>
                        {Object.keys(filterOptions.tags).map((category, cid) => (
                          <React.Fragment key={`option-${cid}`}>
                            <AutoCompleteItem
                              value={category}
                              textTransform="capitalize"
                            >
                              <Tag 
                                variant='solid'  
                                colorScheme='blue'
                                borderRadius='full'
                                maxW='max-content'
                                cursor='pointer'
                              >
                                <TagLabel>{category}</TagLabel>
                              </Tag>
                            </AutoCompleteItem>
                            {filterOptions.tags[category].map((subcategory, sid) => (
                              <AutoCompleteItem
                                key={`option-${cid}-${sid}`}
                                value={`${category}-${subcategory}`}
                              >
                                <Tag 
                                  colorScheme='blue'
                                  borderRadius='full'
                                  maxW='max-content'
                                  cursor='pointer'
                                  ml={5}
                                >
                                  <TagLabel>{subcategory}</TagLabel>
                                </Tag>
                              </AutoCompleteItem>
                            ))}
                          </React.Fragment>
                        ))}
                      </AutoCompleteList>
                    </AutoComplete>
                    <FormErrorMessage>{form.errors.tags}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Button
                mt={4}
                colorScheme='blue'
                isLoading={other.isSubmitting}
                type='submit'
              >
                Submit
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Create new round</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>Round</FormLabel>
                <Input placeholder='New round' />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='blue' mr={3}>Create</Button>
              <Button variant='ghost' onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </Box>
  );
};

export default AboutPage;
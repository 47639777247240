import React, { useEffect, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom'
import { Box, Heading, Link as ChakraLink, Text, Stack, Flex, Show, IconButton, useDisclosure, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Divider, Image } from '@chakra-ui/react';
import { IoMdSettings } from "react-icons/io";
import { HiOutlineMenu } from "react-icons/hi";
import { jwtDecode } from 'jwt-decode';

const NavBar = ({ isSignedIn }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [profilePicture, setProfilePicture] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return;

    const decoded = jwtDecode(token);
    const { picture } = decoded;
    console.log('picture', picture)
    setProfilePicture(picture);
  }, []);

  const navBarLeftOptions = [
    {
      'name': 'Contact Us',
      'link': '/contact-us'
    },
  ];

  return (
    <Box borderColor='blue.500' borderBottomWidth={2} boxShadow='md'>
      <Flex direction='row' justify='space-between' align='center' gap={{ base: 16, md: 32 }} px={5} maxW='container.xl' mx='auto'>
        <ChakraLink as={ReactRouterLink} to='/' _hover={{ textDecoration: 'none' }}>
          <Stack direction='row' align='center'>
            <Image src={`${process.env.PUBLIC_URL}/favicon.svg`} alt='Chemhub Logo' height='30px' />
            <Text 
              fontSize='xl'
              fontWeight='bold'
              textColor='blue.500'
              py={3}
            >
              Chemhub
            </Text>
          </Stack>
        </ChakraLink>
        <Show above='md'>
          <Flex direction='row' justify='space-between' align='center' flexGrow={1}>
            <Stack direction='row' gap={8}>
              {navBarLeftOptions.map(option => (
                <ChakraLink as={ReactRouterLink} to={option.link} key={option.name}>
                  <Text>{option.name}</Text>
                </ChakraLink>
              ))}
            </Stack>
            <Stack direction='row' gap={4} align='center'>
              {!isSignedIn ? (
                <ChakraLink as={ReactRouterLink} to='/sign-in'>
                  <Text>Sign In</Text>
                </ChakraLink>
              ) : (
                <ChakraLink as={ReactRouterLink} to='/profile'>
                  <Image src={profilePicture} borderRadius='full' alt='profile-picture' height='30px' />
                </ChakraLink>
              )}
              <ChakraLink as={ReactRouterLink} to='/setting'>
                <IconButton size='lg' icon={<IoMdSettings />} colorScheme='blue' variant='ghost' />
              </ChakraLink>
            </Stack>
          </Flex>
        </Show>
        <Show below='md'>
          <IconButton 
            size='lg' 
            icon={<HiOutlineMenu />} 
            colorScheme='blue' 
            variant='ghost'
            onClick={onOpen}
          />
        </Show>
        <Drawer
          isOpen={isOpen}
          placement='top'
          onClose={onClose}
          size='full'
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader py={1}>
              <ChakraLink as={ReactRouterLink} to='/' _hover={{ textDecoration: 'none' }} onClick={onClose}>
                <Stack direction='row' align='center'>
                  <Image src={`${process.env.PUBLIC_URL}/favicon.svg`} alt='Chemhub Logo' height='30px' />
                  <Text 
                    fontSize='xl'
                    fontWeight='bold'
                    textColor='blue.500'
                    py={3}
                  >
                    Chemhub
                  </Text>
                </Stack>
              </ChakraLink>
            </DrawerHeader>
            <DrawerBody>
              <Stack direction='column' gap={2} align='center'>
                {navBarLeftOptions.map(option => (
                  <React.Fragment key={option.name}>
                    <ChakraLink as={ReactRouterLink} to={option.link} onClick={onClose}>
                      <Text>{option.name}</Text>
                    </ChakraLink>
                    <Divider borderColor='blue.500' borderWidth='0.5px'/>
                  </React.Fragment>
                ))}
                <ChakraLink as={ReactRouterLink} to='/sign-in' onClick={onClose}>
                  <Text>Sign In</Text>
                </ChakraLink>
                <Divider borderColor='blue.500' borderWidth='0.5px'/>
                <ChakraLink as={ReactRouterLink} to='/profile' onClick={onClose}>
                  <Text>Profile</Text>
                </ChakraLink>
                <Divider borderColor='blue.500' borderWidth='0.5px'/>
                <ChakraLink as={ReactRouterLink} to='/setting' onClick={onClose}>
                  <Text>Setting</Text>
                </ChakraLink>
                <Divider borderColor='blue.500' borderWidth='0.5px'/>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  );
};

export default NavBar;

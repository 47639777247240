import React from 'react';
import { Box, Heading, Stack, Text } from '@chakra-ui/react';

const ContactPage = () => {
  return (
    <Box maxW='container.md' px={6} py={3} mx='auto'>
      <Heading size='lg' my={5}>Contact Us</Heading>
      <Text ml={1}>Have a question or want to report a problem?</Text>
      <Stack direction='row' wrap='wrap' gap={0} ml={4}>
        <Text ml={-3} mr={1}>Email us at</Text>
        <Text mr={1} color='blue.500' fontWeight='bold'>yttriumtrioxide@gmail.com</Text>
        <Text mr={1}>or message</Text>
        <Text mr={1} color='blue.500' fontWeight='bold'>tantan.tantan</Text>
        <Text mr={1}>on Discord.</Text>
      </Stack>
    </Box>
  );
};

export default ContactPage;
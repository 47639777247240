import { Box, Heading, ListItem, Stack, Text, UnorderedList, Link as ChakraLink } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

const ContributePage = () => {
  return (
    <Box maxW='container.md' px={6} py={3} mx='auto' >
      Coming Soon!
      {/*<Heading size='lg' my={5}>Want to contribute?</Heading>
      <Text>You can help us by:</Text>
      <UnorderedList ml={6}>
        <ListItem>
          <ChakraLink as={RouterLink} to='/add-problem' color='blue.500' fontWeight='bold'>
            Adding a problem
          </ChakraLink>
        </ListItem>
        <ListItem>Labeling existing problems</ListItem>
      </UnorderedList>*/}
    </Box>
  );
}

export default ContributePage;
import { Box, Button, Flex, Heading, Modal, Stack, Text, useDisclosure, ModalOverlay, ModalBody, ModalHeader, ModalContent, ModalCloseButton, ModalFooter, AlertDialog, AlertDialogFooter, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader } from '@chakra-ui/react';
import React, { useState, useEffect, useRef } from 'react';
import SERVER_HOST from '../config';
import SignedOutModal from './SignedOutModal';

const SettingPage = ({ isSignedIn, setIsSignedIn }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  // For signed out modal
  const [isSignedOutModalOpen, setIsSignedOutModalOpen] = useState(false);
  const onCloseSignedOutModal = () => setIsSignedOutModalOpen(false);

  const [problemStatuses, setProblemStatuses] = useState(() => {
    const saved = localStorage.getItem('problemStatuses');
    const initialValue = saved != 'undefined' && saved ? JSON.parse(saved) : {};
    return initialValue;
  });
  useEffect(() => {
    console.log('update problem statuses', problemStatuses);
    if (!isSignedIn) {
      localStorage.setItem('problemStatuses', JSON.stringify(problemStatuses));
    } else {
      updateProblemStatuses({});
    }
  }, [problemStatuses]);


  const updateProblemStatuses = async (problemStatuses) => {
    const response = await fetch(`${SERVER_HOST}/update-problem-statuses`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ problemStatuses }),
    });

    const data = await response.json();
    console.log('data from jwt', data)
    if (data.error && data.error == 'JWT expired') {
      console.log('session expired')
      localStorage.removeItem('token');
      setIsSignedOutModalOpen(true);
      setIsSignedIn(false);
      return;
    }

    if (!response.ok) {
      console.log('Error updating problem statuses:');
    }
  };

  const handleClearStatuses = () => {
    setProblemStatuses({});
    onClose();
  }

  return (
    <Box maxW='container.md' px={5} py={3} mx='auto'>
      <Heading size='lg' my={5}>Settings</Heading>
      <Stack direction='column' px={3}>
        <Stack direction='row' align='center' gap={5}>
          <Text>Clear all problem statuses:</Text>
          <Button size='sm' colorScheme='red' onClick={onOpen}>Clear</Button>
        </Stack>
      </Stack>
      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Warning</AlertDialogHeader>
          <ModalBody>
            This will delete all your problem status data. Are you sure you want to continue?
          </ModalBody>
          <AlertDialogFooter>
            <Button mr={3} onClick={onClose} ref={cancelRef}>Cancel</Button>
            <Button colorScheme='red' onClick={handleClearStatuses}>Delete</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <SignedOutModal isOpen={isSignedOutModalOpen} onClose={onCloseSignedOutModal} />
    </Box>
  );
};

export default SettingPage;
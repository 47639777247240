import { Box, Divider, Flex, Heading, NumberInput, NumberInputField, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import filterOptions from '../filterOptions';
import _ from 'lodash';
import Filter from './Filter';

const FilterBar = ({ competitions, setCompetitions, yearRange, setYearRange, tags, setTags, filterSz }) => {
  console.log('rerender');

  const debouncedOnChange1 = _.debounce(val => {
    setYearRange([val ? val : 1900, yearRange[1]]);
  }, 300);

  const debouncedOnChange2 = _.debounce(val => {
    setYearRange([yearRange[0], val ? val : 2024]);
  }, 300);

  return (
    <Stack direction='column' gap={3} mb={3}>
      <Box px={1}>
        <Heading size={filterSz} mb={1}>Year Range</Heading>
        <Flex gap={3} align='center'>
          <NumberInput 
            size={filterSz}
            defaultValue={yearRange[0]} 
            min={1900}
            max={2024}
            step={1}
            onChange={(val) => debouncedOnChange1(val)}                           
            width='100%'
          >
            <NumberInputField borderRadius='md' />
          </NumberInput>
          <Text>to</Text>
          <NumberInput  
            size={filterSz}
            defaultValue={yearRange[1]} 
            min={1900}
            max={2024}
            step={1}
            onChange={(val) => debouncedOnChange2(val)}
            width='100%'
          >
            <NumberInputField borderRadius='md' />
          </NumberInput>
        </Flex>
      </Box>
      <Divider borderColor='gray.600' borderWidth='0.5px'/>
      <Filter 
        filterSz={filterSz}
        tagSz='md'
        tagBorderRadius='0'
        filterName='Competitions'
        filterItems={competitions}
        setFilterItems={setCompetitions}
        options={filterOptions.competitions}
      />
      <Divider borderColor='gray.600' borderWidth='0.5px'/>
      <Filter 
        filterSz={filterSz}
        tagSz={filterSz}
        tagBorderRadius='full'
        filterName='Topic Tags'
        filterItems={tags}
        setFilterItems={setTags}
        options={filterOptions.tags}
      />
    </Stack>
  );
};

export default FilterBar;
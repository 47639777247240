import { ChakraProvider, Alert, AlertIcon, AlertTitle, AlertDescription, Stack, CloseButton, useDisclosure } from '@chakra-ui/react'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './HomePage'
import ProblemPage from './ProblemPage';
import NavBar from './NavBar';
import SettingPage from './SettingPage';
import ContactPage from './ContactPage';
import SignInPage from './SignInPage';
import ContributePage from './ContributePage';
import AddProblemPage from './AddProblemPage';
import ProfilePage from './ProfilePage';
import NotFoundPage from './NotFoundPage';
import { useEffect, useState } from 'react';

function App() {
  const {
    isOpen: isVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: true })
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsSignedIn(true);
    }
  }, []);

  return (
    <ChakraProvider>
      <GoogleOAuthProvider clientId='873971762700-e7t1ggor0akjr4tmo27s877kbknf3qoq.apps.googleusercontent.com'>
        <Router>
          <NavBar isSignedIn={isSignedIn} />
          {isVisible && (
            <Alert status='warning' py={2}>
              <Stack direction='row' width='100%' justify='center' align='center'>
                <AlertIcon />
                <AlertTitle>Disclaimer</AlertTitle>
                <AlertDescription textAlign='center'>ChemHub is currently in testing, data may not be accurate</AlertDescription>
                <CloseButton
                  alignSelf='flex-start'
                  onClick={onClose}
                  borderRadius='full'
                />
              </Stack>
            </Alert>
          )}
          <Routes>
            <Route path='/' element={<HomePage isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} />} />
            <Route path='/problem/:initIndex' element={<ProblemPage isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} />} />
            <Route path='/contact-us' element={<ContactPage />} />
            <Route path='/setting' element={<SettingPage isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} />} />
            <Route path='/sign-in' element={<SignInPage isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} />} />
            <Route path='/contribute' element={<ContributePage />} />
            <Route path='/add-problem' element={<AddProblemPage />} />
            <Route path='/profile' element={<ProfilePage setIsSignedIn={setIsSignedIn} />} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </ChakraProvider>
  );
}

export default App;

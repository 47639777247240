import React from 'react';
import { Button, Link as ChakraLink, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const SignedOutModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Session Expired</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Your session has expired. Please log in again.
        </ModalBody>
        <ModalFooter>
          <ChakraLink as={RouterLink} to='/sign-in'>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Sign In
            </Button>
          </ChakraLink>
          <Button variant='ghost' colorScheme='blue' mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SignedOutModal;
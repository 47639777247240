import React, { useEffect, useState } from 'react';
import { Box, Heading, Stack, Text, Link as ChakraLink, Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import SERVER_HOST from '../config';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const SignInPage = ({ isSignedIn, setIsSignedIn }) => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      console.log('token', token)
      setIsSignedIn(true);
      const decoded = jwtDecode(token);
      const { email } = decoded;
      setEmail(email);
    }
  }, []);

  const decodeCredentials = async (response) => {
    const ticket = await fetch(`${SERVER_HOST}/google-auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: response.credential }),
    });
    const data = await ticket.json();
    const token = data.token;
    localStorage.setItem('token', token);

    const decoded = jwtDecode(token);
    console.log('decoded', decoded)
    const { email } = decoded;
    setEmail(email);
    setIsSignedIn(true);
    navigate('/');
  }

  const logout = () => {
    // Remove the JWT from localStorage
    localStorage.removeItem('token');
  
    // Update state to reflect that user is no longer authenticated
    setEmail(null);
    setIsSignedIn(false);
  };

  return (
    <Box textAlign='center' mt={10} maxW='container.md' mx='auto' px={5}>
      {!isSignedIn ? (
        <Stack direction={{ base: 'column', sm: 'row' }} align='center' justify='center' gap={{ base: 2, sm: 5 }}>
          <Text>Sign in with Google:</Text>
          <GoogleLogin
            onSuccess={response => {
              console.log(response);
              decodeCredentials(response);
            }}
            onError={() => {
              console.log('Login Failed');
            }}
          />
        </Stack>
      ) : (
        <Stack direction='column' gap={3} align='center'>
          <Text>You are already signed in as <Text as='span' color='blue.500' fontWeight='bold'>{email}</Text>.</Text>
          <Button colorScheme='blue' onClick={logout} maxW='min-content'>Sign Out</Button>
        </Stack>
      )}
    </Box>
  );
};

export default SignInPage;
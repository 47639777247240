import { Button, Flex, HStack, Show, Tooltip, VStack, useRadioGroup } from '@chakra-ui/react';
import React from 'react';
import RadioCard from './RadioCard';

const InputBar = ({ problem, handleSubmission, handleShowSolution, answer, setAnswer, showSolution }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: 'react',
    value: answer,
    onChange: (val) => setAnswer(val),
  });

  const group = getRootProps();

  return (
    <VStack gap={2} width='100%'>
      <Flex width='100%' gap={6} px={3} maxW='container.md' justify='center'>
        {problem.problem_json.answer_type == 'MC-ABCD' && (
          <HStack gap={3} {...group} width='100%'>
            {['A', 'B', 'C', 'D'].map((value) => {
              const radio = getRadioProps({ value })
              return (
                <RadioCard key={value} {...radio}>
                  {value}
                </RadioCard>
              )
            })}
          </HStack>
        )}
        {/*<Input
          name='answer'
          value={answer}
          onChange={e => setAnswer(e.target.value)}
          flexGrow={1}
          placeholder='Enter Answer'
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleSubmission();
            }
          }}
        />*/}
        <Show above='md'>
          <HStack gap={3}>
            <Tooltip label={problem.problem_json.answer_key ? '' : 'No answer available'}>
              <>
                {problem.problem_json.answer_type != 'FR' && (
                  <Button 
                    isDisabled={!problem.problem_json.answer_key}
                    onClick={handleSubmission}
                    minW='min-content'
                    borderWidth='1px'
                    borderColor='gray.300'
                  >
                    Check
                  </Button>
                )}
              </>
            </Tooltip>
            <Tooltip label={problem.problem_json.solution_exists ? '' : 'No solution available'}>
              <Button
                colorScheme='blue'
                isDisabled={!problem.problem_json.solution_exists}
                minW='min-content'
                onClick={handleShowSolution}
              >
                {showSolution ? 'Hide Solution' : 'Show Solution'}
              </Button>
            </Tooltip>
          </HStack>
        </Show>
      </Flex>
      <Show below='md'>
        <HStack gap={3}>
          <Tooltip label={problem.problem_json.answer_key ? '' : 'No answer available'}>
            <>
              {problem.problem_json.answer_type != 'FR' && (
                <Button 
                  isDisabled={!problem.problem_json.answer_key}
                  onClick={handleSubmission}
                  minW='min-content'
                  size={{ base: 'sm', sm: 'md' }}
                >
                  Check
                </Button>
              )}
            </>
          </Tooltip>
          <Tooltip label={problem.problem_json.solution_exists ? '' : 'No solution available'}>
            <Button
              colorScheme='blue'
              isDisabled={!problem.problem_json.solution_exists}
              minW='min-content'
              onClick={handleShowSolution}
              size={{ base: 'sm', sm: 'md' }}
            >
              {showSolution ? 'Hide Solution' : 'Show Solution'}
            </Button>
          </Tooltip>
        </HStack>
      </Show>
    </VStack>
  );
};

export default InputBar;